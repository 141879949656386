<template>
  <div>
    <h1 v-if="false">Baby Nerd Tracker</h1>

    <div v-if="babies">
      <select v-model="baby_id" style="font-size: 24pt; color: black; font-weight: bold">
        <option v-for="baby in babies" :key="baby.id" :value="baby.id">{{ baby.name }}</option>
      </select>
      <v-icon class="mb-2">mdi-chevron-down</v-icon>
      <div style="font-size: 80%; color: #7d7d7d">
        {{ durationString(new Date(babies.find((x) => x.id == baby_id).birthdate), new Date()) }}
      </div>
      <div v-if="false">
        <v-chip v-for="baby in babies" :key="baby.id" small class="mb-1">
          {{ baby.name }}
        </v-chip>
      </div>
    </div>

    <!-- features -->
    <div>
      <v-chip-group v-model="view">
        <v-chip small filter class="mb-1" value="bibe">Lactancia</v-chip>
        <v-chip small filter class="ml-1 mb-1" value="peso">Peso</v-chip>
        <v-chip small filter class="ml-1 mb-1" value="pediatra">Pediatra</v-chip>
        <v-chip small filter class="ml-1 mb-1" value="meds">Medicinas</v-chip>
      </v-chip-group>
    </div>

    <!-- bibe -->
    <div v-if="view == 'bibe'">
      <!--graph-->
      <div>
        <v-sheet color="black">
          <v-sparkline
            :value="[...values_bibe_minutes].slice(1, 8).reverse()"
            smooth
            color="#ccc"
            height="100"
            padding="24"
            stroke-linecap="round"
            line-width="2"
          >
            <template v-slot:label="item"> {{ Math.floor(item.value / 60) }}h {{ item.value % 60 }}m </template>
          </v-sparkline>
        </v-sheet>
      </div>

      <!-- list bibe -->
      <v-list>
        <v-list-item
          v-for="(event, i) in events.filter((e) => e.type == 'bibe')"
          :key="event.id"
          @click="editEvent(event)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ mapType(event.type) }} {{ event.rating ? event.rating + '⭐' : '' }}
            </v-list-item-title>

            <v-list-item-subtitle>{{ event.when ? event.when.replace('T', ' ') : '' }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text
              >{{ Math.floor(values_bibe_minutes[i] / 60) }}h
              {{ values_bibe_minutes[i] % 60 }}m</v-list-item-action-text
            >
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <!-- list peso -->
    <div v-if="view == 'peso'">
      <!--graph-->
      <div>
        <v-sheet color="black">
          <v-sparkline
            :value="[...values_peso].slice(0, 9).reverse()"
            smooth
            color="#ccc"
            height="100"
            padding="24"
            stroke-linecap="round"
            line-width="2"
          >
            <template v-slot:label="item"> {{ item.value }}g </template>
          </v-sparkline>
        </v-sheet>
      </div>
      <!-- list -->
      <v-list>
        <v-list-item v-for="event in events.filter((e) => e.type == 'peso')" :key="event.id" @click="editEvent(event)">
          <v-list-item-content>
            <v-list-item-title>
              {{ mapType(event.type) }}
            </v-list-item-title>

            <v-list-item-subtitle>{{ event.when.replace('T', ' ') }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text>{{ event.peso }}g</v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <!-- pediatrician -->
    <div v-if="view == 'pediatra'">
      <p>
        Añade eventos en el <router-link to="/gcalendar">calendario</router-link> y márcalos como "Pediatra" para que
        salgan aquí.
      </p>
      <v-list>
        <v-list-item
          v-for="event in events.filter((e) => e.type == 'pediatra')"
          :key="event.id"
          @click="editEvent(event)"
        >
          <v-list-item-content>
            <v-list-item-title> {{ event.title }} </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.when ? event.when.replace('T', ' ') : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            {{ event.flag ? '🚩' : '' }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <!--  meds -->
    <div v-if="view == 'meds'">
      <v-list>
        <v-list-item v-for="event in events.filter((e) => e.type == 'meds')" :key="event.id" @click="editEvent(event)">
          <v-list-item-content>
            <v-list-item-title> {{ event.what }} </v-list-item-title>
            <v-list-item-subtitle>
              {{ event.when.replace('T', ' ') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action style="font-size: 70%">
            {{ event.createdBy }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!--edit dialog-->
    <v-dialog v-if="editing" v-model="editing" scrollable max-width="600">
      <BabyEventEdit
        :event_id="item_edit.event_id"
        :baby_id="item_edit.baby_id"
        :type="item_edit.type"
        @done="done($event)"
      />
      <v-card v-if="false">
        <v-card-title v-if="false">Event</v-card-title>
        <v-card-text class="mt-4">
          <div>
            <v-select
              label="baby"
              :items="
                babies.map((x) => {
                  return { text: x.name, value: x.id }
                })
              "
              v-model="item_edit.baby_id"
            ></v-select>
            <v-select label="type" :items="['bibe', 'peso', 'pediatra']" v-model="item_edit.type"></v-select>

            <!-- additional data -->
            <div>
              <!-- bibe -->
              <div v-if="item_edit.type == 'bibe'">
                <div style="margin: auto; text-align: center">
                  <v-rating
                    hover
                    size="40"
                    v-model="item_edit.rating"
                    color="orange"
                    background-color="orange"
                  ></v-rating>
                </div>
                <v-text-field label="when" type="datetime-local" v-model="item_edit.when"></v-text-field>
              </div>

              <!-- peso -->
              <div v-if="item_edit.type == 'peso'">
                <v-text-field
                  style="font-size: 16pt; text-align: right"
                  type="number"
                  label="grams"
                  pattern="\d*"
                  v-model="item_edit.peso"
                ></v-text-field>
                <v-text-field label="when" type="datetime-local" v-model="item_edit.when"></v-text-field>
              </div>

              <!-- pediatra -->
              <div v-if="item_edit.type == 'pediatra'">
                <v-text-field label="hora de la cita" type="datetime-local" v-model="item_edit.when"></v-text-field>
                <v-text-field label="title" v-model="item_edit.title"></v-text-field>

                <v-text-field
                  label="Google Calendar Id"
                  v-model="item_edit.gcal"
                  placeholder="event id in google calendar"
                ></v-text-field>

                <v-chip-group v-if="false" v-model="pediatra_tabs" mandatory>
                  <v-chip>Before</v-chip>
                  <v-chip>During/After</v-chip>
                </v-chip-group>
                <v-tabs grow v-model="pediatra_tabs">
                  <v-tab>Before</v-tab>
                  <v-tab>During/After</v-tab>
                </v-tabs>
                <v-textarea v-show="pediatra_tabs == 0" rows="1" auto-grow v-model="item_edit.dudas"> </v-textarea>
                <v-textarea v-show="pediatra_tabs == 1" rows="1" auto-grow v-model="item_edit.notas"> </v-textarea>
                <v-checkbox label="flag" v-model="item_edit.flag"></v-checkbox>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editing = false" depressed color="secondary">cancel</v-btn>
          <v-btn @click="deleteItem(item_edit)" depressed color="error">delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save" depressed color="success">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div style="height: 60px">
      <!-- buffer to scroll-->
    </div>

    <!-- events (debug) -->
    <div style="font-family: monospace; margin-top: 20px; font-size: 8pt">
      <!-- selected baby-->
      <div>
        {{ baby_id }}
      </div>
      <div style="margin-top: 20px" v-if="false">
        {{ events }}
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'
import { v4 as uuidv4 } from 'uuid'
import BabyEventEdit from './BabyEventEdit.vue'

export default {
  components: {
    BabyEventEdit,
  },
  computed: {
    values_bibe_minutes() {
      //returns an array
      let x = this.events.filter((a) => a.type == 'bibe')
      let output = []
      for (let i = 0; i < x.length; i++) {
        const a = i == 0 ? new Date() : new Date(x[i - 1].when)
        const b = new Date(x[i].when)
        const duration = a.getTime() - b.getTime()
        const minutes = Math.round(duration / 1000 / 60)
        output.push(minutes)
      }
      return output
    },
    values_peso() {
      let x = this.events.filter((a) => a.type == 'peso')
      let output = x.map((a) => +a.peso)
      return output
    },
  },
  watch: {
    baby_id() {
      this.fetchEvents()
    },
  },
  data() {
    return {
      view: 'bibe',
      babies: null,
      baby_id: '',
      events: [],
      editing: false,
      item_edit: {},
      pediatra_tabs: 0,
      loading: false,
    }
  },
  methods: {
    done(shallRefresh) {
      this.editing = false
      if (shallRefresh) this.fetchEvents()
    },
    mapType(type) {
      let m = {
        bibe: 'Biberón 🍼',
        peso: 'Peso',
        pediatra: 'Pediatra',
      }

      return m[type] || type
    },
    getCurrentDateTimeLocal() {
      const now = new Date()
      const offset = now.getTimezoneOffset()
      const adjustedDate = new Date(now.getTime() - offset * 60 * 1000)
      return adjustedDate.toISOString().slice(0, 16)
    },
    async fetchBabies() {
      let x = await api.apiFetch('/baby-tracker/babies/')
      if (x.ok) {
        this.babies = await x.json()
        this.baby_id = this.babies?.[0].id
        this.fetchEvents()
      }
    },
    async fetchEvents() {
      if (!this.baby_id) return
      this.loading = true
      let x = await api.apiFetch('/baby-tracker/events?baby_id=' + this.baby_id)
      if (x.ok) {
        this.events = await x.json()
      }
      this.loading = false
    },
    editEvent(event) {
      this.item_edit = { ...event }
      this.editing = true
    },
    addNewItem() {
      this.item_edit = {
        baby_id: this.baby_id,
        event_id: uuidv4(),
        type: this.view,
        when: this.getCurrentDateTimeLocal(),
      }
      this.editing = true
    },
    async deleteItem(item) {
      let conf = confirm('Are you sure?')
      if (!conf) return

      let x = await api.apiFetch(`/baby-tracker/events?baby_id=${item.baby_id}&event_id=${item.event_id}`, {
        method: 'DELETE',
      })
      if (x.ok) {
        this.editing = false
        this.fetchEvents()
      } else {
        alert('Something went wrong it seems')
      }
    },
    async save() {
      this.loading = true
      let x = await api.apiFetch('/baby-tracker/events', {
        method: 'POST',
        body: JSON.stringify(this.item_edit),
      })
      if (x.ok) {
        this.editing = false
        this.fetchEvents()
      }
      this.loading = false
    },

    durationString(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)

      let years = endDate.getFullYear() - startDate.getFullYear()
      let months = endDate.getMonth() - startDate.getMonth()
      let days = endDate.getDate() - startDate.getDate()
      let hours = endDate.getHours() - startDate.getHours()
      let minutes = endDate.getMinutes() - startDate.getMinutes()
      let seconds = endDate.getSeconds() - startDate.getSeconds()

      // Correct negative seconds/minutes/hours by "borrowing"
      if (seconds < 0) {
        seconds += 60
        minutes--
      }
      if (minutes < 0) {
        minutes += 60
        hours--
      }
      if (hours < 0) {
        hours += 24
        days--
      }

      // Correct negative days by "borrowing" from month
      if (days < 0) {
        // Get total days in the *previous* month of endDate
        const tempDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0)
        const daysInPrevMonth = tempDate.getDate()
        days += daysInPrevMonth
        months--
      }

      // Correct negative months by "borrowing" from year
      if (months < 0) {
        months += 12
        years--
      }

      // Now decide how to present the output
      if (years > 0) {
        // Example: "3 años 4 meses"
        // We ignore days/hours/minutes/seconds once we have at least 1 year
        let result = `${years} año${years !== 1 ? 's' : ''}`
        if (months > 0) {
          result += ` y ${months} mes${months !== 1 ? 'es' : ''}`
        }
        return result
      } else if (months > 0) {
        // Example: "10 meses 12 días"
        // We ignore hours/minutes/seconds if at least 1 month
        let result = `${months} mes${months !== 1 ? 'es' : ''}`
        if (days > 0) {
          result += ` y ${days} día${days !== 1 ? 's' : ''}`
        }
        return result
      } else if (days > 0) {
        // Example: "5 días"
        // Under 1 month, but at least 1 day.
        // If you want to be more granular (like 5 días y 4 horas),
        // you can append hours below:
        let result = `${days} día${days !== 1 ? 's' : ''}`

        // If desired, you can add hours/minutes/seconds if it’s a newborn scenario
        // e.g.:
        // if (hours > 0) {
        //   result += ` y ${hours} hora${hours !== 1 ? 's' : ''}`;
        // } else if (minutes > 0) {
        //   result += ` y ${minutes} minuto${minutes !== 1 ? 's' : ''}`;
        // }

        return result
      } else {
        // Under 1 day, so show hours/minutes/seconds
        if (hours > 0) {
          // Example: "8 horas 12 minutos"
          let result = `${hours} hora${hours !== 1 ? 's' : ''}`
          if (minutes > 0) {
            result += ` y ${minutes} minuto${minutes !== 1 ? 's' : ''}`
          }
          return result
        } else if (minutes > 0) {
          // Example: "45 minutos"
          let result = `${minutes} minuto${minutes !== 1 ? 's' : ''}`
          if (seconds > 0) {
            result += ` y ${seconds} segundo${seconds !== 1 ? 's' : ''}`
          }
          return result
        } else {
          // Example: "30 segundos"
          return `${seconds} segundo${seconds !== 1 ? 's' : ''}`
        }
      }
    },
  },
  mounted() {
    this.fetchBabies()
  },
}
</script>

<style>
input::-webkit-date-and-time-value {
  text-align: left !important;
}
</style>
