<template>
  <v-app>
    <!-- <v-app style="background-color: #f1f1f1">-->
    <v-app-bar app color="primary" dense dark>
      <div class="d-flex align-center">
        <router-link to="/" style="color: white">Ernest</router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn target="_blank" icon @click="showMenu = !showMenu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="showMenu" temporary right app>
      <v-list dense nav>
        <!-- Web routes -->
        <v-list-item v-for="(route, index) in routes" :key="index" link :to="route.to">
          <v-list-item-icon>
            <v-icon>{{ route.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div style="height: 80px"></div>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api from '@/assets/api'

export default {
  name: 'App',

  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters(['username']),
    ...mapState(['token']),
    routes() {
      return api.userAppRoutes()
    },
  },
  mounted() {
    //redirect to paraguas.pasalo.es ---
    let host = location.hostname
    if (host == 'pasaparaguas.netlify.app') {
      location.href = location.href.replace('pasaparaguas.netlify.app', 'paraguas.pasalo.es')
    }
    //end of redirect

    //sessionId ----

    //sessionId & refreshId can be used to get around caches while fetching data:
    //sessionId - lasts as long as the session storage:
    let x = sessionStorage.sessionId
    if (!x) sessionStorage.sessionId = new Date().getTime()

    //refreshId - changes with each refresh
    sessionStorage.refreshTime = new Date().getTime()
  },
}
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
</style>
