<template>
  <div>
    <div class="gcalendar-item" @click="onClick(item)">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: top">
          <!-- date-->
          <div style="display: flex; flex-direction: column; padding-right: 15px; text-align: center">
            <div>{{ month }}</div>
            <div>{{ day }}</div>
            <div class="subtext">
              {{ weekday }}
            </div>
          </div>
          <!-- title-->
          <div>
            <div style="font-weight: bold">
              {{ item.summary }}
            </div>
            <div v-if="item.location" class="subtext">
              {{ item.location }}
            </div>
          </div>
        </div>
        <div class="subtext" style="min-width: 40px">
          {{ time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import api from '@/assets/api'

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  watch: {
    value(newValue) {
      this.item = { ...newValue }
    },
  },
  computed: {
    startDateTime() {
      let s = this.item.start.date ? this.item.start.date : this.item.start.dateTime
      return DateTime.fromISO(s, { zone: this.item.start.timeZone })
    },
    month() {
      return this.startDateTime.toFormat('MMM').toUpperCase()
    },
    day() {
      return this.startDateTime.toFormat('dd')
    },
    weekday() {
      return this.startDateTime.toFormat('EEE')
    },
    time() {
      if (this.item.start.dateTime) {
        return this.startDateTime.toFormat('HH:mm')
      } else {
        return 'all day'
      }
    },
  },
  data() {
    return {
      item: this.value,
      edit: false,
    }
  },
  methods: {
    onClick(item) {
      this.$router.push('/gcalendar/event/' + item.id)
    },
    async create(item) {
      let x = await api.apiFetch('/gcalendar/create', {
        method: 'POST',
        body: JSON.stringify(item),
      })
      if (x.ok) {
        let j = await x.json()
        alert('saved!')
      } else {
        alert('error')
      }
    },
    async save(item) {
      let x = await api.apiFetch('/gcalendar/update', {
        method: 'POST',
        body: JSON.stringify(item),
      })
      if (x.ok) {
        let j = await x.json()
        alert('saved')
      } else {
        alert('error')
      }
    },
    gtime2string() {
      /*let s = gtime.date ? new Date(gtime.date) : new Date(gtime.dateTime) //.toLocaleDateString()
      return s.toLocaleDateString() + ' ' + s.toLocaleTimeString()
      */
      return this.startDateTime.toFormat('yyyy-MM-dd HH:mm:ss')
    },
  },
}
</script>

<style>
.gcalendar-item {
  margin: auto;
  /*margin-bottom: 5px;*/
  border-bottom: 1px solid #ccc;
  /*border: 1px solid #ccc;*/
  /*border-radius: 5px;*/
  padding: 5px;
  max-width: 500px;
  cursor: pointer;
}
.subtext {
  font-size: 80%;
  color: grey;
}
</style>
