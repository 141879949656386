<template>
  <div>
    <h2 style="display: flex; justify-content: space-between">
      <span> {{ event_id ? 'Edit' : 'New' }} event </span>

      <div>
        <v-btn color="primary" text to="/gcalendar">List</v-btn>
        <v-btn color="success" text @click="save()">Save</v-btn>
      </div>
    </h2>

    <div>
      <v-text-field
        dense
        v-model="item.summary"
        hide-details
        outlined
        class="mb-2"
        clearable
        label="Title"
      ></v-text-field>
      <v-textarea
        dense
        rows="1"
        auto-grow
        v-model="item.location"
        hide-details
        outlined
        class="mb-2"
        clearable
        label="Location"
      ></v-textarea>
      <div style="margin-bottom: 8px">
        Tag:
        <ChipDropdown
          v-model="item.alex.tag"
          :items="[
            '👨‍⚕️ pediatra',
            '🏫 colegio',
            '👶 guarde',
            '🤰 gine',
            '🎁 anniversary',
            '🏥 hospital',
            '🍺 social',
            '✏️ papeleo',
          ]"
        />
      </div>

      <!-- pediatra extra controls -->
      <div v-if="item.alex.tag && item.alex.tag.includes('pediatra')" style="margin-bottom: 10px">
        <v-select v-model="item.alex.baby_id" :items="babies" label="Child"></v-select>

        <v-text-field
          v-if="item.alex.baby_id && false"
          label="Baby Tracker Event ID"
          v-model="item.alex.baby_tracker_event_id"
        >
          <template v-slot:append-outer>
            <v-btn color="primary" depressed @click="babyTrackerIt()"> <v-icon>mdi-plus</v-icon> </v-btn>
          </template>
        </v-text-field>
      </div>

      <v-textarea
        dense
        rows="2"
        auto-grow
        v-model="item.alex.description"
        hide-details
        outlined
        class="mb-1"
        clearable
        label="Description"
      ></v-textarea>

      <div v-if="item.alex.url">
        <a :href="item.alex.url" target="_blank">See in Baby Tracker</a>
      </div>

      <!-- date time -->
      <div>
        <v-checkbox v-model="allday" label="All day">All day</v-checkbox>
        <DateTimeEdit v-model="item.start" :allday="allday" label="Start Date" />
        <DateTimeEdit v-model="item.end" :allday="allday" label="End Date" />
      </div>

      <p class="subtext" v-if="item.creator && item.creator.email">Creator: {{ item.creator.email }}</p>
      <p class="subtext">event_id: {{ item.id }}</p>
    </div>
  </div>
</template>

<script>
import ChipDropdown from '@/components/ChipDropdown.vue'
import api from '@/assets/api'
import { v4 as uuidv4 } from 'uuid'
import DateTimeEdit from './DateTimeEdit.vue'

export default {
  components: {
    DateTimeEdit,
    ChipDropdown,
  },
  props: {
    event_id: {
      type: String,
      default: '',
      required: false,
    },
  },
  watch: {
    event_id() {
      this.fetchGoogleEvent()
    },
    item(newValue, oldValue) {
      this.$emit('change', newValue)
      this.allday = newValue?.start?.date ? true : false
    },
  },
  computed: {},
  data() {
    return {
      emptyItem: {
        id: undefined,
        summary: '',
        alex: { description: '', tag: '' },
        start: {
          //today
          date: new Date().toISOString().substring(0, 10), // '2023-07-07',
          //dateTime: '2023-09-04T12:00:00+02:00',
          //timeZone: 'Europe/Madrid',
        },
        end: {
          //tomorrow
          date: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10), //'2023-07-07',
        },
      },
      item: {
        alex: {},
      },
      allday: false,

      //baby tracker
      babies: [],
    }
  },
  methods: {
    async fetchBabies() {
      let x = await api.apiFetch('/baby-tracker/babies/')
      if (x.ok) {
        let babies = await x.json()
        this.babies = babies.map((b) => {
          return { text: b.name, value: b.id }
        })
      }
    },
    async fetchBabyTrackerEvent(baby_id, event_id) {
      let x = await api.apiFetch(`/baby-tracker/events?baby_id=${baby_id}&event_id=${event_id}`)
      let item = undefined
      if (x.ok) {
        let text = await x.text()
        if (text) {
          item = JSON.parse(text)
        } else {
          item = {
            baby_id: baby_id,
            event_id: event_id,
            type: 'pediatra',
          }
        }
        return item
      }
    },
    /**
     * This creates or update an event in baby-tracker that matches this calendar event.
     */
    async babyTrackerIt() {
      const baby_id = this.item.alex.baby_id
      if (!baby_id) {
        alert('To sync this with Baby Tracker, next time select a child id!')
        return
      }
      if (!this.item.alex.baby_tracker_event_id) this.item.alex.baby_tracker_event_id = uuidv4()
      const event_id = this.item.alex.baby_tracker_event_id
      const url = `https://paraguas.pasalo.es/#/baby-tracker/baby/${baby_id}/event/${event_id}`
      this.item.alex.url = url

      //fetch the event (or return a basic one if not found) - so we don't lose it's existing data
      let item = await this.fetchBabyTrackerEvent(baby_id, event_id)

      //update the event with calendar event priority data:
      item.when = this.item.start.dateTime ? this.item.start.dateTime.slice(0, 16) : this.item.start.date & 'T00:00' //this handles all-day vs specific time
      item.title = this.item.summary
      item.gcal_description = this.item.alex.description
      item.gcal = this.item.id

      let x = await api.apiFetch('/baby-tracker/events', {
        method: 'POST',
        body: JSON.stringify(item),
      })
    },
    onDateChange(newDate) {
      console.log(newDate)
    },
    close() {
      this.$emit('close')
    },

    isJson(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },

    async save() {
      let item = { ...this.item }
      //first, sync with baby-tracker if "pediatra"
      if (this.item.alex.tag.includes('pediatra') && this.item.alex.baby_id) {
        await this.babyTrackerIt() //TBC
      }

      let path = item.id ? '/gcalendar/update' : '/gcalendar/create'

      //fix end date (for events that last one day, end date is start date + 1, not same)
      if (this.allday && item.start.date == item.end.date) {
        let ed = new Date(item.end.date)
        ed.setDate(ed.getDate() + 1)
        item.end.date = ed.toISOString().substring(0, 10)
      }
      console.log('aaa')
      // end fix date

      //move "alex" to description
      if (!item.alex) {
        item.alex = { description: item.description || '' }
      }
      item.description = JSON.stringify(item.alex)
      delete item.alex
      //end move "alex"

      let x = await api.apiFetch(path, {
        method: 'POST',
        body: JSON.stringify(item),
      })

      if (x.ok) {
        let j = await x.json()
        this.$emit('saved', j.data)
        this.$emit('close')

        if (!this.event_id) this.$router.push('/gcalendar/event/' + j.data.id)
      } else {
        this.$emit('error')
      }
    },
    async fetchGoogleEvent() {
      //reset
      this.item = { ...this.emptyItem }

      //then try to replace with backend info
      if (!this.event_id) return

      let x = await api.apiFetch(`/gcalendar/get?event_id=${this.event_id}`)
      if (x.ok) {
        let item = await x.json()

        //parse the description to get our metadata:
        if (this.isJson(item.description)) {
          item.alex = JSON.parse(item.description)
        } else {
          item.alex = { description: item.description, tag: '' }
        }
        if (!item.alex.tag) item.alex.tag = ''
        if (!item.alex.description) item.alex.description = ''
        this.item = { ...item }
      } else {
        console.log('error fetching the event')
      }
    },
  },

  mounted() {
    this.fetchGoogleEvent()
    this.fetchBabies()
  },
}
</script>

<style lang="scss" scoped></style>
