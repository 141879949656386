<template>
  <v-card>
    <!-- 
    <v-select
      label="baby"
      :items="
        babies.map((x) => {
          return { text: x.name, value: x.id }
        })
      "
      v-model="item.baby_id"
    ></v-select>
    <v-select label="type" :items="types" v-model="item.type"></v-select>
    -->
    <v-card-title>
      {{ item.type || type }}
    </v-card-title>
    <v-card-text>
      <!-- additional data -->
      <div style="min-height: 100px">
        <!-- bibe -->
        <div v-if="item.type == 'bibe'">
          <div style="margin: auto; text-align: center">
            <v-rating hover size="40" v-model="item.rating" color="orange" background-color="orange"></v-rating>
          </div>
          <v-text-field label="when" type="datetime-local" v-model="item.when"></v-text-field>
        </div>

        <!-- peso -->
        <div v-if="item.type == 'peso'">
          <v-text-field
            style="font-size: 16pt; text-align: right"
            type="number"
            label="grams"
            pattern="\d*"
            v-model="item.peso"
          ></v-text-field>
          <v-text-field label="when" type="datetime-local" v-model="item.when"></v-text-field>
        </div>

        <!-- pediatra -->
        <div v-if="item.type == 'pediatra'">
          <div v-if="item.gcal" style="margin-bottom: 20px">
            <router-link :to="'/gcalendar/event/' + item.gcal" target="_blank">Editar en el calendario</router-link>
          </div>
          <p v-else>
            Ojo: mejor crear esto desde el <router-link to="/gcalendar/event/">calendario</router-link> y luego apuntar
            aquí detalles.
          </p>
          <v-text-field
            label="hora de la cita"
            :disabled="!!item.gcal"
            type="datetime-local"
            v-model="item.when"
          ></v-text-field>
          <v-text-field label="title" :disabled="!!item.gcal" v-model="item.title"></v-text-field>

          <v-textarea label="Descripción" :disabled="!!item.gcal" rows="1" auto-grow v-model="item.gcal_description">
          </v-textarea>

          <v-textarea label="Notas" rows="1" auto-grow v-model="item.notas"> </v-textarea>
          <v-checkbox label="flag" v-model="item.flag"></v-checkbox>
        </div>

        <!-- meds -->
        <div v-if="item.type == 'meds'">
          <v-select :items="['Vitamina D', 'Enema']" label="What" v-model="item.what"></v-select>
          <v-text-field label="when" type="datetime-local" v-model="item.when"></v-text-field>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="cancel" depressed color="secondary">cancel</v-btn>
      <v-btn @click="deleteItem()" depressed color="error">delete</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="save" depressed color="success">save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from '@/assets/api'
export default {
  props: {
    baby_id: {
      type: String,
      default: '',
    },
    event_id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'bibe',
      required: false, //only when creating a new event
    },
  },
  watch: {
    event_id() {
      this.fetchEvent()
    },
  },
  data() {
    return {
      item: {},
      types: ['bibe', 'peso', 'pediatra'],
      loading: false,
      pediatra_tabs: 0,
    }
  },
  methods: {
    async fetchEvent() {
      let x = await api.apiFetch(`/baby-tracker/events?baby_id=${this.baby_id}&event_id=${this.event_id}`)
      if (x.ok) {
        let text = await x.text()
        if (text) {
          this.item = JSON.parse(text)
        } else {
          this.item = {
            baby_id: this.baby_id,
            event_id: this.event_id,
            type: this.type,
            when: this.getCurrentDateTimeLocal(),
          }
        }
      }
    },
    cancel() {
      this.$emit('done', false)
    },
    async deleteItem() {
      let conf = confirm('Are you sure?')
      if (!conf) return

      let x = await api.apiFetch(`/baby-tracker/events?baby_id=${this.item.baby_id}&event_id=${this.item.event_id}`, {
        method: 'DELETE',
      })
      if (x.ok) {
        this.$emit('done', true)
      } else {
        alert('Something went wrong it seems')
      }
    },
    async save() {
      this.loading = true
      let x = await api.apiFetch('/baby-tracker/events', {
        method: 'POST',
        body: JSON.stringify(this.item),
      })
      if (x.ok) {
        this.$emit('done', true)
      }
      this.loading = false
    },
    getCurrentDateTimeLocal() {
      const now = new Date()
      const offset = now.getTimezoneOffset()
      const adjustedDate = new Date(now.getTime() - offset * 60 * 1000)
      return adjustedDate.toISOString().slice(0, 16)
    },
  },
  mounted() {
    this.fetchEvent()
  },
}
</script>

<style lang="scss" scoped></style>
