<template>
  <div>
    <!-- new item dialog-->
    <v-dialog v-model="showEditDialog" max-width="500" fullscreen>
      <CalendarEdit :value="editItem" @close="showEditDialog = false" @saved="onNewCreated($event)" />
    </v-dialog>

    <!-- loading -->
    <div v-if="loading">
      <v-progress-circular
        style="position: fixed; left: calc(50% - 40px); top: calc(50% - 40px)"
        :size="80"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-else>
      <!-- calendar title / desc -->
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>

      <!-- search -->
      <v-text-field
        id="searchGroup"
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
        class="mb-2"
      ></v-text-field>

      <!-- tags -->

      <div style="margin-bottom: 8px">
        <v-chip
          @click="filterTag = filterTag == tag ? '' : tag"
          small
          :outlined="filterTag != tag"
          class="mr-1"
          v-for="tag in tags"
          :key="tag"
          >{{ tag }}</v-chip
        >
      </div>

      <!-- items -->
      <div>
        <div v-for="(item, i) in items" :key="item.id">
          <CalendarListItemVue v-if="visibleIndexes.includes(i)" :value="item" @change="items[i] = $event" />
        </div>
      </div>
    </div>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import api from '@/assets/api'
import utils from '@/assets/utils'
import CalendarListItemVue from './CalendarListItem.vue'
import CalendarEdit from './CalendarEdit.vue'

export default {
  components: {
    CalendarListItemVue,
    CalendarEdit,
  },
  watch: {
    showEditDialog() {
      this.editItem = { ...this.emptyItem }
    },
  },
  computed: {
    tags() {
      let output = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        const tag = item.alex.tag

        if (!output.includes(tag) && tag) {
          output.push(tag)
        }
      }
      return output
    },
    visibleIndexes() {
      let output = []
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i]

        //search text
        let add1 = false
        let text = [item.summary, item.alex ? item.alex.description : ''].join(' ')
        if (utils.search.searchMatch(this.searchText, text)) {
          add1 = true
        }

        //tag filter
        let add2 = true
        if (this.filterTag) {
          if (!item.alex || item.alex.tag != this.filterTag) add2 = false
        }

        if (add1 && add2) {
          output.push(i)
        }
      }
      return output
    },
  },
  data() {
    return {
      loading: false,
      searchText: '',
      filterTag: '',
      title: 'Google Calendar',
      description: 'A calendar',
      items: [],
      showEditDialog: false,

      editItem: {},
      emptyItem: {
        summary: '',
        alex: { description: '', tag: '' },
        start: {
          //today
          date: new Date().toISOString().substring(0, 10), // '2023-07-07',
          //dateTime: '2023-09-04T12:00:00+02:00',
          //timeZone: 'Europe/Madrid',
        },
        end: {
          //tomorrow
          date: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10), //'2023-07-07',
        },
      },
    }
  },
  methods: {
    onNewCreated(e) {
      this.showEditDialog = false
      this.fetchData()
    },
    addNewItem() {
      this.editItem = { ...this.emptyItem } //reset
      this.showEditDialog = true
    },
    isJson(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    async fetchData() {
      this.loading = true
      let date = new Date()
      date.setDate(date.getDate() - 10)
      date = date.toISOString()
      const params = [
        ['d', date],
        ['pageToken', ''],
      ]
      let searchParams = new URLSearchParams(params)
      let x = await api.apiFetch('/gcalendar/get?' + searchParams.toString())
      if (x.ok) {
        let j = await x.json()
        this.title = j.summary
        this.description = j.description
        this.items = j.items.map((x) => {
          if (!this.isJson(x.description)) {
            x.description = JSON.stringify({ description: x.description || '' })
          }
          x.alex = JSON.parse(x.description)
          delete x.description //we will put it back when saving back to google calendar
          return x
        })
      }
      this.loading = false
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style></style>
